@import "buttons";
@import "mixins";
.navbar-custom {
  .navbar-brand {
    p {
      font-size: rem(11px);
      margin-bottom: 0;
      line-height: 1.1;
      text-transform: uppercase;
    }
  }
  .navbar-toggler {
  }
  .navbar-nav {
    padding-top: rem(32px);
    .nav-item {
      &.dropdown {
        .dropdown-menu {
          padding-left: rem(20px);
          li {
            .dropdown-item {
              line-height: 2;
              &.active {
              }
            }
          }
        }
      }
    }
    .nav-link {
      font-size: rem(18px);
      line-height: 1.6;
      color: var(--primary-black);
      background: var(--primary-white);
      font-weight: 400;
      padding: rem(10px 20px);
      &:hover,
      &.active {
        //background: var(--background-gray);
        //color: var(--primary-color);
      }
      &:hover {
        color: var(--primary-color);
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
    }
  }
  .search {
    .dropdown-toggle {
      display: none;
      &:after {
        display: none;
      }
    }
    .dropdown-menu-search {
      display: block;
      &.show {
        width: 100%;
        left: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
  .mobile-menu {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
    button {
      order: 2;
      //margin-left: 32px;
    }
    .dropdown {
      position: relative;
      order: 1;
      padding: rem(12px 16px);
      .dropdown-toggle {
        font-size: rem(18px);
        width: 50px;
        text-overflow: clip;
        overflow: hidden;
        bdi {
          display: none;
        }
      }
      .mini-cart-content {
        background: var(--primary-white);
        z-index: 9999;
        width: 100%;
        height: 90vh;
      }
    }
    .wishlist-link {
      order: 0;
      padding: rem(12px 16px);
    }
  }
}
.navbar-collapse {
  &.collapse {
    &.show {
      height: 100vh;
    }
  }
}

.menu-toggler {
  font-size: rem(16px);
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  background: var(--primary-white);
  color: var(--primary-black);
  padding: rem(6px 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  .bi-list {
    fill: var(--primary-black);
    width: 20px;
    height: 20px;
    margin-right: rem(8px);
  }
}

@include media-breakpoint-down(md) {
}
@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
  .navbar-custom {
    &.top {
      .navbar-brand {
      }
    }
    .navbar-brand {
      img {
        width: 175px;
      }
      p {
        font-size: rem(13px);
        margin-bottom: 0;
        line-height: 1.1;
        text-transform: uppercase;
      }
    }
    .navbar-collapse {
      .search-form {
        padding: rem(15px);
        cursor: pointer;
      }
    }
    .navbar-nav {
      align-items: center;
      padding-top: 0;
      .nav-item {
        .nav-link,
        .dropdown-item {
          font-size: rem(16px);
          line-height: 24px;
          color: var(--primary-black);
          background: var(--primary-white);
          font-weight: 400;
          padding: rem(10px 20px);
          &:hover,
          &.active {
            //background: var(--background-gray);
            color: var(--primary-color);
          }
        }
        &.active {
          .nav-link {
            //background: var(--background-gray);
            color: var(--primary-color);
          }
        }
        .nav-link {
        }
        &.dropdown {
          .dropdown-menu {
            padding-left: 0;
          }
        }
      }
      .dropdown-menu {
        padding-left: 0;
        margin-top: rem(9px);
        border: 1px solid var(--border-gray);
        box-shadow: 0 9px 8px rgba(0, 0, 0, 0.25);
        background: var(--primary-white);
        .dropdown-item {
          &:hover {
            background: var(--primary-gray);
          }
          &.active {
            background: var(--primary-gray);
          }
        }
      }
    }
    .search {
      position: inherit;
      .dropdown-toggle {
        display: block;
        &:after {
          display: none;
        }
      }
      .dropdown-menu-search {
        display: none;
        &.show {
          display: block;
          width: 100%;
          left: 0;
          margin: 0;
          border-radius: 0;
        }
      }
    }
    .options {
      .nav-item {
        .nav-link {
          padding: rem(10px 15px);
        }
      }
    }
  }
  .navbar-collapse {
    width: 100%;
  }
  .menu-toggler {
    display: none;
  }
  #mini-cart {
    padding: rem(10px 0 10px 15px);
    margin-left: rem(48px);
    .nav-link {
      font-size: rem(14px);
      svg {
        margin-right: 8px;
      }
    }
    &.dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
          margin-top: 0;
        }
      }
    }
  }
}

.cart-customlocation {
  font-size: rem(14px);
  .amount {
    font-size: rem(14px);
  }
}
