.btn {
  border-radius: 50px;
  color: var(--primary-black);
  &:hover {
    color: var(--primary-black)
  }
}
.btn-primary,
.button-primary {
  background: var(--background-gray);
  border: none;
  color: var(--primary-color);
  padding: rem(10px 25px);
  text-align: center;
  font-size: rem(16px);
  line-height: 1.4;
  font-weight: 400;
  &:hover,
  &:active,
  &:focus {
    background: var(--primary-black);
    border: var(--primary-gray);
    color: var(--white)
  }
  &.disabled,
  &:disabled {
    background: var(--border-gray);
    color:var(--primary-black)
  }
}
.btn-secondary {
  background: var(--primary-color);
  border: none;
  color: var(--white);
  padding: rem(8px 16px);
  text-align: center;
  font-size: rem(16px);
  line-height: 1.6;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  display: block;
  &:hover,
  &:active,
  &:focus {
    background: var(--primary-black);
    border: var(--primary-gray);
    color: var(--white)
  }
  &.disabled,
  &:disabled {
    background: var(--border-gray);
    color:var(--primary-black)
  }
}

.btn-second-outline {
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: rem(8px 16px);
  text-align: center;
  font-size: rem(16px);
  line-height: 1.6;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  display: block;
  &:hover,
  &:active,
  &:focus {
    background: var(--primary-black);
    border: var(--primary-gray);
    color: var(--white)
  }
  &.disabled,
  &:disabled {
    background: var(--border-gray);
    color:var(--primary-black)
  }
}