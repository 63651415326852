:root {
  color-scheme: light dark;
  --primary-black: #222222;
  --primary-white: #FFFFFF;
  --primary-color: #D4AF37;
  --primary-gray: #F7F7F7;
  --border-gray: #D9D9D9;
  --text-gray: #505B73;
  --background-gray: #F7F9FB;
  --placeholder: var(--primary-gray);
  --information-blue: #4c82f7;
  --error-red: #ed1b35;
  --success-green: #00c9a7;
  --warning-orange: #ffba08;
  --footer-text-color: var(--primary-gray);
  --carousel-text-color: var(--white);
  --image-filter: grayscale(1);
  $carousel-control-color: #000 !important;

}

[data-theme=dark] {
  color-scheme: light dark;
  --primary-black: #B0B3B8;
  --white: #18191a;
  --primary-color: #D4AF37;
  --primary-gray: #242526;
  --border-gray: #3E4042;
  --text-gray: #E4E6EB;
  --background-gray: #242526;
  --placeholder: var(--primary-gray);
  --information-blue: #4c82f7;
  --error-red: #ed1b35;
  --success-green: #00c9a7;
  --warning-orange: #ffba08;
  --footer-text-color: var(--primary-gray);
  --carousel-text-color: var(--white);
  --image-filter: grayscale(0);

  img:not([src*=".svg"]) {
    filter: var(--image-filter);
  }

  --image-filter: grayscale(10%);

  img:not([src*=".svg"]) {
    filter: var(--image-filter);
  }
  svg {
    fill: var(--text-gray);
  }
}