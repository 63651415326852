.form-control,
.acf-input input,
textarea {
  border: 1px solid var(--border-gray);
  padding: rem(10px 12px);
  border-radius: 4px;
  font-size: rem(14px);
  color: var(--primary-black);
  margin-bottom: rem(16px);
  background: var(--primary-white);
  &:focus {
    box-shadow: none;
    border-color: var(--primary-color);
  }

  &::placeholder {
    color: var(--text-gray) !important;
    font-size: rem(14px)
  }

}
.form-select {
  border: 1px solid var(--border-gray);
  padding: rem(10px 12px);
  border-radius: 4px;
  font-size: rem(14px);
  color: var(--primary-black);
  margin-bottom: rem(16px);
  &:focus {
    box-shadow: none;
    border-color: var(--primary-color);
  }

  &::placeholder {
    color: var(--text-gray) !important;
    font-size: rem(14px)
  }
}
.form-check-input {
  border: 1px solid var(--border-gray);
  border-radius: 4px;
  font-size: rem(14px);
  color: var(--primary-black);
  //margin-bottom: rem(16px);
  //background: var(--primary-white);
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:checked {
    background-color: var(--primary-black);
    border-color:  var(--primary-black);
  }
}

.acf-input input,
.acf-input textarea {
  width: 100%;
}
//.div[class^='form-row-'] {
  .form-check-label,
  .acf-label label{
    color: #000;
    font-size: rem(14px);
    font-weight: 600;
    line-height: 1.5;
    cursor: pointer;
    //margin-bottom: rem(4px)
  }

#billing_country_field,
#shipping_country_field {
  margin-bottom: rem(16px);
  span {
    display: block;
    border: 1px solid var(--border-gray);
    padding: rem(10px 12px);
    border-radius: 4px;
    font-size: rem(14px);
    color: var(--primary-black);
    //background: var(--primary-gray)
  }
}
.dropdown-menu-search {
  padding-left: 0 !important;
}
.search-form {
  position: relative;
  .form-label {
    //font-size: rem(24px);
    //font-weight: 700
    display: none;
  }
  .form-control {
    padding-left: rem(36px);
  }
  svg {
    position: absolute;
    left: 10px;
    top: 14px
  }
}
//}
//
//}
//.search-form {
//  .form-label {
//    display: none;
//  }
//}
//.message {
//  font-size: rem(12px);
//  line-height: 1.2;
//  margin-bottom: rem(20px);
//}
//
//#message {
//  margin-bottom: rem(16px);
//  &.updated {
//    border: 1px solid var(--success-green);
//    padding: rem(8px 16px);
//    background: var(--success-green);
//  }
//  p {
//    margin-bottom: 0;
//    color: var(--white);
//    font-size: rem(16px);
//    font-weight: 700;
//  }
//}
@include media-breakpoint-up(lg) {
  .search-form {
    svg {
      left: 26px;
      top: 29px
    }
  }
}
//
//.form-row, .form-control {
//  margin-bottom: rem(16px);
//  label {
//    display:block;
//    font-size: rem(14px);
//    font-weight: 600;
//    line-height: 1.4;
//    margin-bottom: rem(4px);
//    color: var(--primary-black);
//  }
//  .woocommerce-input-wrapper {
//    input,
//    textarea {
//      background: var(--primary-white);
//      padding: rem(10px 12px);
//      border-radius: 4px;
//      color: var(--primary-black);
//      font-size: rem(14px);
//      line-height: 19px;
//      border: 1px solid #d9d9d9;
//    }
//  }
  #billing_city_field,
  #billing_postcode_field,
  #billing_phone_field,
  #billing_email_field,
  #shipping_city_field,
  #shipping_postcode_field {
    display: inline-block
  }
  #billing_city_field,
  #shipping_city_field {
    width: 65%;
    margin-right: 3%
  }
  #billing_postcode_field,
  #shipping_postcode_field {
    width: 32%
  }
  #billing_phone_field,
  #billing_email_field {
    width: 49%
  }
  #billing_phone_field {
    margin-right: 2%
  }
//}
//.qty {
//  width: 48px !important;
//}

.acf-field {
  &.acf-field-634f10e079f80 {
    .acf-label {
      display: none
    }
    .acf-input {
      .acf-true-false {
       label {
         display: flex;
         input[type="checkbox"] {
           width: 20px;
           height: 20px;
           padding-right: rem(10px)
         }
         .message {
           font-size: rem(11px);
           color: var(--text-gray)
         }
       }
      }

    }
  }
}
#acf-form > div.acf-fields.acf-form-fields.-top > div.acf-field.acf-field-group.acf-field-634f103879f7b > div.acf-label {
  display:none !important
}
.acf-field-group + div {

}