@import "bootstrap";
@import "custom/font";
@import "custom/mixins";
@import "custom/colors";
@import "custom/buttons";
@import "custom/navbar";
@import "custom/form";

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--primary-black);
  background: var(--primary-white);
}
*::selection {
  background: rgba(212, 175, 55, 0.95);
  color: var(--primary-white);
  padding: 10px;
}
html {
  -webkit-text-size-adjust: 100%;
  --initial-color-mode: light;
  scroll-behavior: smooth;
}
h1,
h2,
h3 {
  line-height: 1.5;
  font-weight: 400;
  font-family: "Playfair Display", serif;
}
h1 {
  font-size: rem(36px);
  margin-bottom: rem(0);
}
h2 {
  font-size: rem(32px);
  margin-bottom: rem(16px);
}
h3 {
  font-size: rem(24px);
  margin-bottom: rem(16px);
}
h4 {
  font-size: rem(18px);
  margin-bottom: 0;
}
h5 {
  font-size: rem(12px);
}

p {
  font-size: rem(16px);
  line-height: rem(25px);
  margin-bottom: rem(16px);
  font-weight: 400;
  color: var(--primary-black);
  a {
    color: var(--primary-red);
    font-weight: 400;
    text-decoration: none;
    word-break: break-all;
    word-wrap: break-word;
    &:hover {
      color: darken(#d01032, 10);
      text-decoration: none;
    }
  }
}

a {
  color: var(--primary-color);
  &:hover {
    color: var(--primary-black);
  }
}

main {
  background: var(--primary-white);
}
section {
  &:not(.hero) {
    padding-top: rem(8px);
    padding-bottom: rem(8px);
  }
  &.gray {
    background: var(--background-gray);
  }
}
.woocommerce-breadcrumb {
  margin-bottom: rem(16px);
  font-size: rem(14px);
  a {
    color: var(--primary-black);
    font-size: rem(14px);
  }
}
.search-block {
  margin-right: rem(16px);
  cursor: pointer;
  .search-form {
    display: none;
    position: absolute;
    top: 40%;
    right: 0;
    z-index: 90999999;
    width: 30%;
  }
  .search-icon {
    padding: rem(8px 12px);
  }
}

section {
  &:not(.hero) {
    margin-top: rem(48px);
    margin-bottom: rem(72px);
  }
}
#hero {
  margin-top: 0;
  .carousel-item {
    height: 65vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.image-type-slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
    }
    &.color-and-text-type {
      flex-direction: column;
    }
    &.gray {
      background: var(--background-gray);
    }
  }
  h1 {
    font-size: rem(48px);
    line-height: 1.3;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  p {
    font-size: rem(16px);
    line-height: 1.5;
    text-transform: uppercase;
  }
  h1,
  p {
    margin-left: rem(15px);
  }
  img {
    mix-blend-mode: multiply;
    max-width: 100%;
  }
}
.section-description {
  margin-bottom: rem(75px);
}
.section-name {
  .first-col {
    text-align: center;
    .card {
      padding: rem(20px 16px);
      background: var(--primary-white);
      .link-to-collection {
        margin-top: rem(16px);
        color: var(--primary-black);
      }
    }
  }
  .card {
    position: relative;
    padding: rem(62px 16px 16px);
    background: rgb(247, 247, 247);
    background: linear-gradient(
      130deg,
      rgba(247, 247, 247, 1) 33%,
      rgba(255, 255, 255, 1) 100%
    );

    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .card-img-top {
      margin-bottom: rem(5px);
      mix-blend-mode: multiply;
    }
    .card-body {
      padding: 0;
      .sizes {
        opacity: 1;
        transition: opacity 155ms ease-in;
        ul {
          list-style-type: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          padding-left: 0;
          li {
            &:not(:nth-child(1)) {
              padding: rem(2px 8px);
              border: 1px solid var(--text-gray);
              border-radius: 4px;
              font-size: rem(14px);
              line-height: 1.4;
              color: var(--text-gray);
              margin-right: rem(8px);
              margin-bottom: rem(8px);
            }
            &:nth-child(1) {
              font-size: rem(14px);
              line-height: 1.4;
              color: var(--primary-black);
              margin: rem(0 8px 8px 0);
              padding: rem(8px 0 8px 0);
            }
          }
        }
      }
    }
    .card-title {
      a {
        font-size: rem(18px);
        color: var(--primary-black);
        text-decoration: none;
        line-height: 1.5;
        text-transform: none;
        font-weight: 400;
        &:hover {
          color: var(--primary-color);
        }
      }
      .price {
        font-size: rem(14px);
        color: var(--primary-black);
        line-height: 19px;
      }
    }
    .card-footer {
      padding: 0;
    }
  }
  &:nth-of-type(1) {
    background: transparent;
  }
}

.categories {
  .card {
    background: rgb(247, 247, 247);
    background: linear-gradient(
      130deg,
      rgba(247, 247, 247, 1) 33%,
      rgba(255, 255, 255, 1) 100%
    );
    padding: rem(62px 16px 36px);
    .card-img-top {
      mix-blend-mode: multiply;
    }
    .card-body {
      text-align: center;
      a {
        color: var(--primary-black);
      }
      .card-title {
        a {
          text-decoration: none;
        }
      }
      .category-link {
        &:hover {
          color: var(--primary-color);
          text-decoration: none;
        }
      }
    }
  }
}
.advantages {
  .row {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .advantage {
    display: flex;
    margin-bottom: rem(48px);
    max-width: 60%;
    svg {
      width: 36px;
      height: 36px;
      margin-right: rem(16px);
    }
    .description {
      h4 {
        font-size: rem(18px);
        line-height: 1.1;
        font-weight: 600;
      }
      small {
        font-size: rem(12px);
        line-height: 16px;
      }
    }
  }
}
.about {
  p {
    margin-bottom: rem(32px);
    a {
      text-decoration: underline;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
footer {
  margin-top: 48px;
  border-top: 1px solid var(--border-gray);
  background: var(--primary-white);
  color: var(--text-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(68px 0);

  ul {
    list-style-type: none;
    padding: rem(0);
    margin-bottom: rem(32px);
    li {
      a {
        color: var(--primary-black);
        font-size: rem(16px);
        line-height: 2.3;
        text-decoration: none;

        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
  .links {
    display: flex;
    li {
      margin-right: rem(16px);
    }
    a {
      color: var(--primary-black);
      font-size: rem(12px);
      line-height: 2.3;
      text-decoration: none;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .contact-details {
    color: var(--primary-black);
    font-size: rem(16px);
    line-height: 2.3;
    text-decoration: none;
  }
  .legal {
    flex-direction: row;
    align-items: center;
    margin-top: rem(32px);
    font-size: rem(12px);
    font-weight: 400;
    small {
      margin-top: unset;
    }
  }
}

.filters {
  margin-bottom: rem(16px);
  .filters-collapse {
    padding: rem(4px 12px);
    font-size: rem(14px);
    color: var(--primary-black);
    text-decoration: none;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    display: block;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  h4 {
    margin-top: rem(16px);
    font-family: "Playfair Display", serif;
    font-size: rem(18px);
    margin-bottom: rem(8px);
    font-weight: 700;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: rem(32px);

    li {
      margin-bottom: rem(8px);
      a {
        color: var(--primary-black);
        font-size: rem(16px);
        line-height: 1.3;
        text-decoration: none;
      }
      &.active {
        font-weight: 700;
      }
    }
    &.sizes {
      display: flex;
      flex-wrap: wrap;
      li {
        a {
          padding: rem(2px 8px);
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          font-size: rem(14px);
          line-height: 1.4;
          color: var(--primary-black);
          margin-right: rem(8px);
          &:hover,
          &.active {
            background: var(--primary-black);
            color: var(--primary-white);
          }
        }
      }
    }
  }
}

.product-gallery {
  .woocommerce-product-gallery__wrapper {
    .carousel-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style-type: none;
      padding-left: 0;

      .woocommerce-product-gallery__image {
        background: rgb(247, 247, 247);
        background: linear-gradient(
          130deg,
          rgba(247, 247, 247, 1) 33%,
          rgba(255, 255, 255, 1) 100%
        );
        padding: rem(32px);
        margin-bottom: rem(12px);
        // &.carousel-item {
        // display: block;
        // float: unset;
        // margin-right: auto;
        // }
        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin-bottom: rem(12px);
          mix-blend-mode: multiply;
        }
      }
    }
  }
}
.product-description {
  .description {
    margin: rem(20px 0 32px);
    line-height: 1.7;
  }
  .accordion {
    margin-top: rem(32px);
    .accordion-item {
      margin-top: rem(24px);
      .accordion-header {
        width: 100%;
        .accordion-button {
          font-size: rem(16px);
          font-weight: 700;
          background: none;
          border: none;
          color: var(--primary-black);
          width: 100%;
          text-align: left;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .accordion-collapse {
        .accordion-body {
          padding: rem(8px);
          font-size: rem(14px);
          color: var(--primary-black);
        }
      }
    }
  }
}

.order-by-box {
  select {
    //background: var(--primary-white);
    padding: rem(6px 12px);
    border-radius: 4px;
    color: var(--primary-black);
    font-size: rem(14px);
    line-height: 19px;
    border: 1px solid #d9d9d9;
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {
  #hero {
    .carousel-item {
      height: 65vh;
      flex-direction: row;
      &.image-type-slide {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
      }
      &.color-and-text-type {
        flex-direction: column;
      }
      &.gray {
        background: var(--background-gray);
      }
    }
    h1 {
      font-size: rem(64px);
      line-height: 1.5;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }
    p {
      font-size: rem(16px);
      line-height: 1.5;
      text-transform: uppercase;
    }
    h1,
    p {
      margin-left: rem(32px);
    }
    img {
      mix-blend-mode: multiply;
      max-width: 100%;
    }
  }
  .product-description {
    position: sticky;
    top: 0;
    height: 100%;
  }

  .order-by-box {
    display: flex;
    justify-content: flex-end;
    select {
      width: auto;
    }
  }
  .result-and-filters {
    margin-bottom: 0;
  }
  .filters {
    .filters-collapse {
      display: none;
    }
    height: 100%;
    position: sticky;
    top: 32px;
    h4 {
      margin-top: 0;
    }
  }
  .section-name {
    .first-col {
      text-align: center;
      .card {
        justify-content: center;
        background: var(--primary-white);
        .link-to-collection {
          margin-top: rem(24px);
          color: var(--primary-black);
        }
      }
    }
    .card {
      position: relative;
      padding: rem(124px 20px 15px);
      background: rgb(247, 247, 247);
      background: linear-gradient(
        130deg,
        rgba(247, 247, 247, 1) 33%,
        rgba(255, 255, 255, 1) 100%
      );

      svg {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .card-img-top {
        margin-bottom: rem(5px);
        mix-blend-mode: multiply;
      }
      &:hover {
        .card-body {
          .sizes {
            opacity: 1;
          }
        }
      }
      .card-body {
        padding: 0;

        .sizes {
          opacity: 0;
          transition: opacity 155ms ease-in;
          ul {
            list-style-type: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 0;
            li {
              &:not(:nth-child(1)) {
                padding: rem(2px 8px);
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                font-size: rem(14px);
                line-height: 1.4;
                color: var(--primary-black);
                margin-right: rem(8px);
                margin-bottom: rem(8px);
                &:hover {
                }
              }
              &:nth-child(1) {
                font-size: rem(14px);
                line-height: 1.4;
                color: var(--primary-black);
                margin: rem(0 8px 8px 0);
                padding: rem(8px 0 8px 0);
              }
            }
          }
        }
      }
      .card-title {
        a {
          font-size: rem(16px);
          color: var(--primary-black);
          text-decoration: none;
          line-height: 1.5;
          text-transform: none;
          font-weight: 400;
          &:hover {
            color: var(--primary-color);
          }
        }
        .price {
          font-size: rem(14px);
          color: var(--primary-black);
          line-height: 19px;
        }
      }
    }
    &:nth-of-type(1) {
      background: transparent;
    }
  }
  .categories {
    .card {
      //background: rgb(247,247,247);
      //background: linear-gradient(130deg, rgba(247,247,247,1) 33%, rgba(255,255,255,1) 100%);
      padding: rem(124px 100px 36px);
      //.card-img-top {
      //    mix-blend-mode: multiply;
      //}
      //.card-body {
      //    text-align: center;
      //    a {
      //        color: var(--primary-black)
      //    }
      //    .card-title {
      //        a {
      //            text-decoration: none;
      //        }
      //    }
      //    .category-link {
      //        &:hover {
      //            color: var(--primary-color);
      //            text-decoration: none;
      //        }
      //    }
      //}
    }
  }

  .advantages {
    .row {
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
    }
  }
  .product-gallery {
    .woocommerce-product-gallery__wrapper {
      .carousel-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        padding-left: 0;

        .woocommerce-product-gallery__image {
          background: rgb(247, 247, 247);
          background: linear-gradient(
            130deg,
            rgba(247, 247, 247, 1) 33%,
            rgba(255, 255, 255, 1) 100%
          );
          padding: rem(32px);
          margin-bottom: rem(12px);
          width: 365px;
          max-width: 49%;
          &.carousel-item {
            display: block;
            float: unset;
            margin-right: auto;
          }
          img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-bottom: rem(12px);
            mix-blend-mode: multiply;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(48px);
  }
  h2 {
    font-size: rem(48px);
    line-height: 1.2;
    margin-bottom: 0;
  }
  h3 {
    font-size: rem(32px);
    line-height: 1.2;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    .description {
      font-size: rem(16px);
    }
  }

  .cookies {
    .options {
      flex-direction: row;
    }
  }
  .woocommerce-breadcrumb {
    font-size: rem(16px);
    a {
      font-size: rem(16px);
    }
  }
  .filters {
    .dont-collapse-sm {
      display: block;
      height: auto !important;
      visibility: visible;
    }
  }
}

.sr-only {
  display: none;
}
hr {
  border-top: 1px solid var(--border-gray);
  background-color: transparent;
  opacity: 1;
  margin: rem(48px 0 96px);
}

#scrollTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: var(--primary-color);
  color: var(--primary-white);
  cursor: pointer;
  padding: rem(8px 12px);
  border-radius: 2px;
  font-size: 18px;
  font-weight: bold;
  &:hover {
    background-color: var(--primary-black);
    color: var(--primary-white);
  }
}
.hero-404 {
  margin-top: 120px;
}
.cookies {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-gray);
  padding: rem(12px);
  p {
    font-size: rem(10px);
    color: var(--text-gray);
    line-height: 1.5;
    margin-bottom: 0;
  }
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
  .btn-cookies-ok,
  .btn-cookies-no {
    font-weight: 700;
    font-size: rem(12px);
    padding: rem(10px 12px);
  }
  .btn-cookies-ok {
    color: var(--primary-color);
    margin-right: rem(16px);
  }
  .btn-cookies-no {
    color: var(--text-gray);
  }
}

.single-page {
  .page-hero {
    margin-top: rem(32px) !important;
    margin-bottom: rem(32px) !important;
  }
  .page-content {
    h2 {
      font-size: rem(32px);
      line-height: 1.6;
      padding-bottom: rem(32px);
    }
    p {
      font-size: rem(16px);
      line-height: 1.6;
      font-weight: 400;
      color: var(--primary-black);
      margin-bottom: rem(32px);
      ul {
        padding-left: rem(20px);
      }
    }
    ul,
    ol {
      padding-left: rem(28px);
      li {
        &::marker {
          font-weight: 700;
        }
        font-size: rem(20px);
        line-height: 1.6;
        margin-bottom: rem(24px);
        ul,
        ol {
          padding-left: rem(20px);
          list-style-type: lower-alpha;
          li {
            font-size: rem(20px);
            line-height: 1.5;
            font-weight: 400;
            color: var(--text-gray);
            margin-bottom: rem(20px);
          }
        }
        a {
          font-weight: 700;
          color: var(--primary-color);
          text-decoration: none;
          position: relative;
          &:hover {
            color: var(--primary-color);
            transition: border 125ms ease-in;
            border-bottom: 6px solid var(--primary-color);
          }
        }
      }
    }
  }
}

//wc
.wc-block-components-checkbox__input {
  display: none !important;
}
.wc-block-components-checkbox__label {
  font-size: rem(16px);
  line-height: 1.3;
}
.wp-block-woocommerce-filter-wrapper {
  margin-bottom: rem(32px);
  &:nth-of-type(2) {
    ul {
      li {
        .wc-block-components-checkbox {
          label {
            input[type="checkbox"] {
              &:checked ~ span {
                font-weight: 700 !important;
              }
            }
          }
        }
      }
    }
  }
  &:nth-of-type(3) {
    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 0;
      li {
        .wc-block-components-checkbox {
          label {
            input[type="checkbox"] {
              &:checked ~ span {
                border: var(--primary-black);
                background: var(--primary-black);
                color: var(--primary-white);
              }
            }
          }
        }

        .wc-block-components-checkbox__label {
          padding: rem(4px 9px);
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          font-size: rem(14px);
          line-height: 1.4;
          color: var(--primary-black);
          margin-right: rem(8px);
          margin-bottom: rem(8px);
          &:hover {
            //border: var(--primary-black);
            background: var(--primary-black);
            color: var(--primary-white);
          }
        }
        //&:nth-child(1) {
        //    font-size: rem(14px);
        //    line-height: 1.4;
        //    color: var(--primary-black);
        //    margin: rem(0 8px 8px 0);
        //    padding: rem(8px 0 8px 0);
        //}
        &:nth-of-type(1) {
          order: 10;
        }
        &:nth-of-type(2) {
          order: 11;
        }
        &:nth-of-type(3) {
          order: 12;
        }
      }
    }
  }
}
.wc-block-components-checkbox {
  //.wc-block-components-checkbox__input {
  //    &:checked > span{
  //            font-weight: 700;
  //    }
  //}

  .wc-block-components-checkbox__mark {
    display: none !important;
  }
  margin-top: 0 !important;
}

.single_add_to_cart_button,
.checkout-button,
.button,
.wc-backward {
  background: var(--primary-black) !important;
  border: 2px solid var(--primary-black) !important;
  text-align: center;
  color: var(--primary-white) !important;
  padding: rem(10px);
  width: 100%;
  border-radius: 4px;
  font-size: rem(16px);
  font-weight: 700;
  margin-top: rem(25px);
  text-decoration: none;
  &.disabled {
    background: var(--primary-gray) !important;
    border: 2px solid var(--primary-gray) !important;
    color: #666 !important;
    cursor: not-allowed;
  }
  &:hover {
    color: var(--primary-white);
  }
}

.woocommerce-notices-wrapper {
  .woocommerce-message,
  .woocommerce-error,
  .woocommerce-info {
    padding: rem(12px 16px);
    color: var(--primary-black);
    &:before {
      display: none;
    }
  }
  .woocommerce-message {
    border-top: 3px solid var(--success-green) !important;
    background: var(--primary-white);
    .button {
      font-weight: 700;
      color: var(--primary-black);
      text-decoration: none;
      background: none !important;
      border: none !important;
      position: relative;
    }
    &:before {
      display: none;
    }
  }
}
.woocommerce-error {
  font-size: rem(12px);
  font-weight: 400;
}
.woocommerce-info {
  border-top: 3px solid var(--primary-black) !important;
  background: var(--primary-white) !important;
  padding-left: 0 !important;
  color: var(--primary-black);
  &:before {
    display: none !important;
  }
}
.variations {
  tr {
    margin-bottom: rem(16px);
    display: block;
  }
  .label {
    display: block;
    font-size: rem(14px);
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: rem(4px);
  }
  .value {
    display: block;
    position: relative;
    select {
      padding: rem(6px 36px 6px 12px);
      border-radius: 4px;
      color: var(--primary-black);
      font-size: rem(14px);
      line-height: 19px;
      border: 1px solid #d9d9d9;
    }
    .reset_variations {
      position: absolute;
      top: 9px;
      right: -45px;
    }
  }
}
.stock {
  font-size: rem(14px);
  font-weight: 600;
  &.in-stock {
    color: var(--success-green);
  }
  &.out-of-stock {
    color: var(--error-red);
  }
}
//input {
//    background: var(--primary-white);
//    padding: rem(6px 12px);
//    border-radius: 2px;
//    color: var(--primary-black);
//    font-size: rem(14px);
//    line-height: 19px;
//    border: 1px solid #d9d9d9;
//}
.product-thumbnail {
  margin: rem(0 32px 0 0) !important;
  width: 64px;
}
.product-name {
  a {
    color: var(--primary-black) !important;
    text-decoration: none;
  }
}
// .woocommerce table.shop_table tfoot th
.woocommerce {
  table {
    & > :not(caption) > * > * {
      border: none;
    }
    tbody {
      border: none;
    }
    &.shop_table {
      margin-bottom: rem(32px);
      th {
        color: var(--primary-black) !important;
        font-size: rem(16px) !important;
        font-weight: 600 !important;
        padding: rem(12px 16px);
      }
      border: none !important;
      td {
        // border-top: none !important;
        padding: rem(16px) !important;
      }
      tr {
        border: none;
        &.cart_item {
          td {
            // border-bottom: 1px solid var(--border-gray) !important;
          }
        }
      }
      tfoot {
        th {
          border-top: none;
        }
      }
    }
  }
}
.reset_variations {
  color: var(--primary-black) !important;
  font-size: rem(13px) !important;
  font-weight: 600 !important;
  margin-left: rem(12px);
  text-decoration: none;
}
.woocommerce-additional-fields {
  margin-top: rem(0);
  margin-bottom: rem(64px);
}
.woocommerce-checkout-payment {
  background: var(--primary-white) !important;
  .wc_payment_methods {
    padding-left: 0 !important;
    list-style-type: none;
    margin-top: rem(32px);
    margin-bottom: rem(32px);
    li {
      .form-check {
        input {
          margin-top: rem(8px);
          margin-right: rem(8px);
        }
      }
    }
  }
}
.woocommerce-privacy-policy-text {
  p {
    color: var(--primary-black) !important;
    font-size: rem(10px) !important;
    line-height: 15px !important;
  }
}
.mini-cart-content {
  padding: rem(12px) !important;
  background: var(--primary-white);
  max-height: 420px;
  overflow: auto;
  min-width: 300px;
  /* WebKit (Chrome, Edge, Safari, Opera) */
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent; /* Przezroczyste tło */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Domyślnie ukryty */
    border-radius: 3px;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent; /* Domyślnie ukryty */
  }

  // &:hover {
  scrollbar-color: #f6f6f6 transparent; /* Pokazuje się na hover */
  // }
  &:hover {
    scrollbar-color: #dedede transparent; /* Pokazuje się na hover */
  }
  .woocommerce-mini-cart_footer {
    position: sticky;
    width: 100%;
    bottom: 0;
    background: var(--primary-white);
  }
  .woocommerce-mini-cart__buttons {
    display: flex;
    flex-direction: column;
    a {
      display: block !important;
    }
  }
  .woocommerce-mini-cart__total {
    text-align: center;
    font-size: rem(14px);
    padding: rem(12px 0);
    border-bottom: 1px solid var(--border-gray);
    bdi {
      font-size: rem(14px);
    }
  }
  .woocommerce-mini-cart__empty-message {
    font-size: rem(13px);
    padding: rem(12px 16px);
  }
}

.woocommerce-mini-cart__buttons {
  .button {
    padding: rem(6px 16px);
    font-size: rem(13px);
    margin-top: 0;
    margin-bottom: 8px;
    &:hover {
      color: white;
    }
    &:nth-of-type(1) {
      background: var(--primary-white) !important;
      border: 1px solid var(--border-gray);
      color: var(--primary-black) !important;
    }
  }
}

// .minicart-content {
.woocommerce-mini-cart {
  list-style-type: none;
  padding-left: 0;
  .woocommerce-mini-cart-item {
    font-size: rem(13px);
    border-bottom: 1px solid var(--border-gray);
    padding: rem(12px 0);
    .remove {
      color: var(--error-red);
      padding: rem(8px);
      text-decoration: none;
      font-weight: 700;
      font-size: rem(18px);
    }
    a:nth-of-type(2) {
      text-decoration: none;
      font-size: rem(14px);
      color: var(--primary-black);
      font-weight: 600;
    }

    .quantity {
      display: flex;
      min-width: 100px;
      padding-left: 32px;
    }
    .attachment-woocommerce_thumbnail {
      max-width: 36px;
      height: auto;
      margin: rem(0 0 0 12px);
    }
  }
}
// }
.variation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: rem(8px);
  dd {
    margin-left: rem(8px);
  }
  dt,
  dd {
    font-size: rem(13px);
    margin-bottom: 0;
    p {
      font-size: rem(13px) !important;
      margin-bottom: 0 !important;
    }
  }
}

.shop_table {
  width: 100%;
  .woocommerce-cart-form__cart-item {
    .product-remove {
      a {
        color: var(--error-red);
        text-decoration: none;
        font-size: rem(18px);
      }
    }
    .product-thumbnail {
      img {
        width: 64px;
        height: 64px;
      }
    }
    .product-name {
      a {
        text-decoration: underline;
      }
    }
  }
}
.screen-reader-text {
  display: none;
}
.actions-container {
  text-align: right;
  button {
    width: auto;
    &:disabled {
      background-color: var(--primary-gray) !important;
      border: var(--primary-gray) !important;
      color: #666;
    }
  }
}

.zoomImg {
  mix-blend-mode: normal !important;
}

.quantity {
  max-width: 64px;
  label {
    display: none;
  }
}

address {
  margin-bottom: rem(64px);

  a {
    color: var(--primary-black);
    font-size: rem(16px);
    line-height: 1.5;
    //text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

#message {
  border-top: 3px solid var(--success-green);
  padding: rem(12px);
  p {
    margin-bottom: 0;
  }
}
.woocommerce-shipping-totals {
  border-bottom: 1px solid var(--border-gray) !important;
  .woocommerce-shipping-methods {
    list-style-type: none;
    padding-left: 0 !important;
    font-size: rem(14px);
    li {
      margin-bottom: rem(8px);
      .form-check {
        display: flex;
        align-items: center;
        input {
          margin-right: rem(8px);
        }
      }
      label {
        font-size: rem(16px);
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}
.form-check-label {
  abbr {
    display: none;
  }
}
.woocommerce-notice--success {
  border-top: 3px solid var(--success-green);
  padding: rem(12px);
}

.order_details {
  list-style-type: none;
  padding-left: 0;
}
.woocommerce-order-details {
  padding: rem(0);
  margin-top: 0 !important;
  .woocommerce-table {
    tbody {
      tr {
        &:hover {
          background: var(--primary-gray);
        }
        td {
          a {
            text-decoration: underline;
          }
          .wc-item-meta {
            padding-left: 0 !important;
            list-style-type: none;
            li {
              margin-bottom: 0 !important;
              font-size: rem(12px) !important;
              display: flex;
              align-items: center;
              strong {
                margin-right: rem(4px);
              }

              p {
                margin-bottom: 0 !important;
                font-size: rem(14px) !important;
              }
            }
          }
        }
      }
    }
    tfoot {
      border-top: 1px solid var(--border-gray);
    }
  }
}
.woocommerce-shipping-destination {
  font-size: rem(12px) !important;
  margin-bottom: 0 !important;
}
.woocommerce-Price-amount {
  font-weight: 400;
  bdi {
    text-decoration: none;
  }
}
.woocommerce-order-overview {
  display: flex !important;
  padding-left: 0 !important;
  justify-content: space-between;
  margin-bottom: rem(64px);
  flex-direction: column;
  li {
    margin-bottom: 0 !important;
    padding: rem(4px);
    font-size: rem(16px) !important;
    strong {
      display: block;
    }
    //border-bottom: 1px solid var(--border-gray);
  }
}
.wc-proceed-to-checkout {
  .checkout-button {
    display: block;
    color: var(--primary-white) !important;
    width: auto;
  }
}
.onsale {
  position: absolute;
  top: 12px;
  left: 16px;
  font-weight: 700;
  color: var(--primary-color);
  text-transform: uppercase;
}
.woocommerce-bacs-bank-details {
  margin-top: 0;
  h3 {
    display: none;
  }
  .wc-bacs-bank-details {
    padding-left: 0;
    li {
      margin-bottom: 0 !important;
      padding: rem(4px);
      font-size: rem(16px) !important;
      strong {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .woocommerce-order-overview {
    flex-direction: row;
  }
}
.outofstock {
  opacity: 0.7;
  .card-img-top {
    filter: var(--image-filter);
  }
}
.out-of-stock {
  font-weight: 700;
  font-size: rem(14px);
  top: 12px;
  right: 20px;
  color: var(--primary-black);
  z-index: 999999999;
  opacity: 1;
  text-transform: uppercase;
}
.product {
  .out-of-stock {
  }
}

#billing_first_name_field {
  width: 100%;
}
